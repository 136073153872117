import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import { GET_SIMILAR_PRODUCTS } from '../../../graphQL/queries/product';
import ProductCard from '../ProductCard';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    marginBottom: '10px',
  },
}));

const Similar = ({ productId }) => {
  const classes = useStyles();

  const { data: product } = useQuery(GET_SIMILAR_PRODUCTS, {
    variables: {
      id: productId,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      {product?.getSimiliarProductsById.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5" className={classes.title}>
              Similar products
            </Typography>
          </Grid>
          {product?.getSimiliarProductsById &&
            product?.getSimiliarProductsById.map((product, index) => (
              <Grid item xs={6} key={index}>
                <ProductCard product={product} store={product.store} />
              </Grid>
            ))}
        </>
      )}
    </>
  );
};

Similar.propTypes = {
  productId: PropTypes.string,
};

export default Similar;
