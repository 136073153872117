import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import {
  CURRENCY_LOCATIONS,
  FILE_TYPE,
} from '../../../core/constants/constant';
import { addHttps } from '../../../utils/addHttps';

const useStyles = makeStyles({
  article: {
    position: 'relative',
  },
  root: {
    boxShadow: 'none',
    cursor: 'pointer',
  },
  media: {
    position: 'relative',
    margin: 0,
    padding: 0,
    objectFit: 'cover',
    height: '200px',
    width: '100%',
    borderRadius: '5px',
    '@media (max-width: 600px)': {
      height: '150px',
    },
  },
  content: {
    padding: '5px 0px 0px 0px',
    cursor: 'pointer',
  },
  salePrice: {
    color: '#F26B1D',
  },
  title: {
    fontWeight: 600,
    lineHeight: 1,
  },
  strikeThrough: {
    textDecoration: 'line-through',
    opacity: 0.33,
  },
  videoPlayer: {
    '& > *': {
      objectFit: 'cover',
    },
  },
});

const index = ({ product, store }) => {
  const classes = useStyles();
  const router = useRouter();

  const [productCurrency, setProductCurrency] = useState({
    value: 'AUD',
    symbol: '$',
  });

  useEffect(() => {
    const [currency] = CURRENCY_LOCATIONS.filter(
      (location) => location.value == store?.currency,
    );

    setProductCurrency({
      value: currency.value,
      symbol: currency.symbol,
    });
  }, []);

  const ShowImgVideo = () => {
    if (
      product &&
      product.medias[0] &&
      product.medias[0].file_type == FILE_TYPE.VIDEO
    ) {
      return (
        <div>
          <ReactPlayer
            playing={false}
            playsinline={true}
            width="100%"
            height="150px"
            controls={true}
            className={classes.videoPlayer}
            url={product && product.medias[0] && product.medias[0].public_url}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload', //<- this is the important bit
                },
              },
            }}
          />
        </div>
      );
    } else if (
      product &&
      product.medias[0] &&
      product.medias[0].file_type == FILE_TYPE.IMAGE
    ) {
      return (
        <figure className={classes.media}>
          <Image
            src={
              product &&
              product.medias[0] &&
              product.medias[0].thumbnails.product_manage
            }
            layout="fill"
            objectFit="cover"
            alt="post image"
          />
        </figure>
      );
    }
    return null;
  };

  const goToProduct = (product) => {
    if (
      router.asPath == '/s/' + router.query.businessname &&
      product?.website_url
    ) {
      window.open(
        addHttps(product?.website_url),
        '_blank',
        'noopener,noreferrer',
      );
    } else {
      router.push(`/products/${product.slug}`);
    }
  };

  return (
    <article className={classes.article}>
      <Card className={classes.root} onClick={() => goToProduct(product)}>
        <ShowImgVideo />
        <CardContent className={classes.content}>
          <div onClick={() => goToProduct(product)}>
            <Typography className={classes.title} component="strong">
              {product.name}
            </Typography>
            {/* <Typography variant="body2" component="p">
              ${product.price} AUD
            </Typography> */}
            <Typography variant="body2" component="p">
              {!!product?.is_on_sale && product?.sale_price && (
                <span className={classes.salePrice}>
                  {productCurrency?.symbol +
                    product?.sale_price +
                    ' ' +
                    productCurrency?.value}
                </span>
              )}{' '}
              <span
                className={
                  !!product?.is_on_sale &&
                  product?.sale_price &&
                  classes.strikeThrough
                }
              >
                {productCurrency?.symbol +
                  product?.price +
                  ' ' +
                  productCurrency?.value}
              </span>
            </Typography>
          </div>
        </CardContent>
      </Card>
    </article>
  );
};

export default index;
