import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-material-ui-carousel';
import ReactPlayer from 'react-player';
import { FILE_TYPE } from '../../../core/constants/constant';

import Image from 'next/image';

const useStyles = makeStyles(() => ({
  paper: {
    maxHeight: '600px',
  },
  image: {
    width: '100%',
    maxHeight: '600px',
    margin: 'auto',
    objectFit: 'cover',
  },
  carousel: {
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  video: {
    width: '100%',
    maxHeight: '600px',
    '& > *': {
      objectFit: 'cover',
    },
  },
}));

interface ImageProp {
  public_url: string;
  id: number | string;
  thumbnails: {
    product_detail: string;
  };
}

interface CarouselImageProps {
  images: Array<ImageProp>;
}

const CarouselImage = ({ images }: CarouselImageProps) => {
  const classes = useStyles();

  const getImages = () => {
    if (images == null) return;
    const filtered = images.filter(({ public_url }) => {
      return public_url !== '';
    });
    return filtered;
  };

  function checkURL(url) {
    if (url.match(/\.(jpeg|jpg|png|heic)$/)) {
      return 'image';
    } else if (url.match(/\.(mp4|mov)$/)) {
      return 'video';
    }
    return '';
  }

  const ShowImgVideo = ({ image }: { image: ImageProp }) => {
    if (checkURL(image.public_url) == FILE_TYPE.VIDEO) {
      return (
        <ReactPlayer
          playing={false}
          playsinline={true}
          url={image?.thumbnails?.product_detail}
          width="100%"
          controls={true}
          className={classes.video}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload', //<- this is the important bit
              },
            },
          }}
        />
      );
    } else {
      return (
        <Image
          src={image?.thumbnails?.product_detail}
          // src={image?.public_url}
          width={358}
          height={358}
          objectFit="contain"
          alt="product-image"
        />
      );
    }
    //if (checkURL(image.public_url) == FILE_TYPE.IMAGE) {
  };

  return (
    <div className={classes.carousel}>
      {getImages().length === 1 ? (
        <Paper className={classes.paper}>
          <ShowImgVideo image={getImages()[0]} />
        </Paper>
      ) : (
        <Carousel
          indicatorContainerProps={{
            className: '',
            style: {
              bottom: '0',
              top: 'unset',
              marginTop: '-40px',
              paddingBottom: '20px',
            },
          }}
          navButtonsAlwaysInvisible
          autoPlay={false}
        >
          {getImages().map((image, index) => (
            <Paper className={classes.paper} key={index}>
              <ShowImgVideo image={image} />
            </Paper>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselImage;
