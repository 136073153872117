import { Chip, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../../../assets/jss/theme';
import PropTypes from 'prop-types';
import { TiStarburst } from 'react-icons/ti';

const useStyles = makeStyles(() => ({
  description: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '10px',
  },
  chip: {
    backgroundColor: '#fff',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  verified: {
    backgroundColor: '#F26B1D',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  checkIcon: {
    color: '#000 !important',
  },
  filterchips: {
    marginTop: '25px',
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const ProductDescription = ({ product }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" className={classes.description}>
          Product description
        </Typography>
        <Typography component="h6" variant="h6">
          {product && product?.description}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.filterchips}>
          {product &&
            product?.tags.map((tag, index) => (
              <Chip
                key={index}
                className={tag.verified ? classes.verified : classes.chip}
                size="small"
                variant="outlined"
                avatar={
                  tag.verified ? (
                    <TiStarburst className={classes.checkIcon} />
                  ) : (
                    <></>
                  )
                }
                label={tag?.name}
              />
            ))}
        </div>
      </Grid>
    </>
  );
};

ProductDescription.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
};

export default ProductDescription;
