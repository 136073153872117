import gql from 'graphql-tag';

export const GET_PRODUCT_LIST = gql`
  query ($storeId: ID!, $filterTagIds: [ID], $page: Int, $pageSize: Int) {
    getProductsByStore(
      storeId: $storeId
      filterTagIds: $filterTagIds
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        name
        price
        slug
        is_on_sale
        website_url
        sale_price
        store {
          username
          currency
        }
        medias {
          id
          public_url
          file_type
          images {
            explore_posts
          }
          thumbnails {
            product_manage
          }
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;
export const GET_DELIVERY_OPTIONS = gql`
  query {
    deliveryOptions {
      id
      name
      order
      pickup_address
      standard_shipping_price
      international_shipping_price
    }
  }
`;

export const GET_STORE_PRODUCTS = gql`
  query {
    getProductsByAuthStore {
      id
      name
      slug
      medias {
        id
        public_url
        file_type
        images {
          vendor_products
        }
      }
      price
      is_on_sale
      sale_price
    }
  }
`;

export const GET_VARIANT_OPTIONS = gql`
  query {
    variants {
      name
      id
    }
  }
`;

export const GET_PRODUCT_BY_SLUG = gql`
  query ($slug: String!) {
    getProductBySlug(slug: $slug) {
      id
      name
      price
      is_on_sale
      out_of_stock
      sale_price
      sku
      description
      quantity
      currency
      website_url
      tags {
        id
        name
        verified
      }
      medias {
        id
        public_url
        thumbnails {
          product_detail
        }
      }
      variants {
        key
        value
      }
      deliveryOptions {
        id
        name
        order
        pickup_address
        standard_shipping_price
        international_shipping_price
      }
      cart_quantity
      cart_delivery_option_id
      cart_variant
      cart_id
      wishlisted
      carts {
        id
        quantity
        delivery_option_id
        variant
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query ($id: ID!) {
    getProduct(id: $id) {
      id
      name
      price
      is_on_sale
      out_of_stock
      sale_price
      sku
      quantity
      isNotDeleteable
      description
      website_url
      tags {
        id
        name
        tag_type
      }
      medias {
        id
        public_url
        featured
        thumbnails {
          product_manage
          product_detail
        }
      }
      variants {
        key
        value
      }
      deliveryOptions {
        id
        name
        order
        pickup_address
        standard_shipping_price
        international_shipping_price
      }
      cart_quantity
      cart_delivery_option_id
      cart_variant
      cart_id
      wishlisted
      store_currency
      store_address
    }
  }
`;

export const GET_SIMILAR_PRODUCTS = gql`
  query ($id: ID!) {
    getSimiliarProductsById(id: $id) {
      id
      name
      price
      is_on_sale
      sale_price
      slug
      store {
        username
        currency
      }
      medias {
        id
        public_url
        file_type
        thumbnails {
          product_manage
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_SEARCH = gql`
  query ($search: String!, $page: Int, $pageSize: Int) {
    searchProducts(search: $search, page: $page, pageSize: $pageSize) {
      data {
        id
        name
        slug
        medias {
          id
          public_url
          file_type
          images {
            vendor_products
          }
        }
        price
        is_on_sale
        sale_price
        store {
          username
          currency
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;
