import { gql } from '@apollo/client';

export const REMOVE_PRODUCT_FROM_CART = gql`
  mutation removeProductFromCart($cartId: ID!) {
    removeProductFromCart(cartId: $cartId) {
      status
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation addProductToCart($data: CartProductInput!) {
    addProductToCart(input: $data) {
      name
    }
  }
`;

export const UPDATE_CART = gql`
  mutation updateProductToCart($data: CartProductInput!) {
    updateProductToCart(input: $data) {
      name
    }
  }
`;
