import { gql } from '@apollo/client';

export const ADD_REMOVE_PRODUCT_TO_WISHLIST = gql`
  mutation addOrRemoveProductFromWishlist($productId: ID!) {
    addOrRemoveProductFromWishlist(productId: $productId) {
      name
    }
  }
`;

export const ADD_ALL_TO_CART = gql`
  mutation moveWishlistProductsToCart {
    moveWishlistProductsToCart {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT_FROM_WISHLIST = gql`
  mutation deleteProductWishlist($productId: ID!) {
    deleteProductWishlist(productId: $productId) {
      name
    }
  }
`;

export const BOOKMARK_POST = gql`
  mutation bookmarkPost($postId: ID!) {
    bookmarkPost(postId: $postId) {
      created_at
    }
  }
`;

export const REMOVE_BOOKMARK_POST = gql`
  mutation deleteBookmarkPost($postId: ID!) {
    deleteBookmarkPost(postId: $postId) {
      created_at
    }
  }
`;
