export const getFormattedVariant = (formattedVariants) => {
  const variants = formattedVariants.reduce((acc, currVariant) => {
    const addedVariants = acc.filter((data) => data.key == currVariant.key);
    const variantIndex = acc.findIndex((data) => data.key == currVariant.key);

    if (addedVariants.length == 0) {
      acc.push({
        key: currVariant.key,
        value: [{ id: currVariant.value, name: currVariant.value }],
      });
    } else {
      acc[variantIndex].value = [
        ...acc[variantIndex].value,
        { id: currVariant.value, name: currVariant.value },
      ];
    }
    return acc;
  }, []);

  return variants;
};
