export const passwordPattern = () => {
  return new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{8,}',
  );
};

export const phoneAustPattern = () => {
  return new RegExp(
    '^(\\+\\d{2}[ \\-]{0,1}){0,1}(((\\({0,1}[ \\-]{0,1})0{0,1}\\){0,1}[2|3|7|8]{1}\\){0,1}[ \\-]*(\\d{4}[ \\-]{0,1}\\d{4}))|(1[ \\-]{0,1}(300|800|900|902)[ \\-]{0,1}((\\d{6})|(\\d{3}[ \\-]{0,1}\\d{3})))|(13[ \\-]{0,1}([\\d \\-]{5})|((\\({0,1}[ \\-]{0,1})0{0,1}\\){0,1}4{1}[\\d \\-]{8,10})))$',
  );
};

export const phonePattern = () => {
  return new RegExp(
    '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
  );
};
export const phonePatternLoose = () => {
  return new RegExp('^[0-9+#-/ ]*$');
};

export const specialCharPattern = () => {
  return /^[A-Za-z0-9 ]*$/;
};

export const RESET_EMAIL = 'RESET_EMAIL';
export const VENDOR = 'vendor';
export const ORGANISATION = 'organisation';
export const CUSTOMER = 'customer';
export const TERMSCONDITIONS_URL = 'https://hoverit.com/terms-of-service/';
export const PRIVACY_POLICY_URL = 'https://hoverit.com/privacy-policy/';
export const FAQS_URL = 'https://hoverit.com/faqs/';

export const MAX_IMAGE_SIZE: number = 15 * 1024 * 1024; // 30MB
export const MAX_VIDEO_SIZE: number = 250 * 1024 * 1024; // 250MB

export const MAX_VIDEO_DURATION: number = 120; // 90second

export const VALID_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/*',
  'image/heif',
  'image/heic',
];
export const VALID_VIDEO_TYPES = [
  'video/mp4',
  'video/quicktime',
  'video/*',
  'video/mp4',
  'video/x-m4v',
  'video/x-matroska',
  '.mkv',
];
export const FILE_TYPE = { VIDEO: 'video', IMAGE: 'image' };

export const CURRENCY_LOCATIONS = [
  { value: 'AUD', symbol: '$', label: 'Australian dollar' },
  { value: 'USD', symbol: '$', label: 'US dollar' },
  { value: 'EUR', symbol: '€', label: 'Euro' },
  { value: 'SGD', symbol: '$', label: 'Singapore dollar' },
  { value: 'JPY', symbol: '¥', label: 'Japanese yen' },
  { value: 'GBP', symbol: '£', label: 'Pound sterling' },
  { value: 'BGN', symbol: 'лв', label: 'Bulgarian lev' },
  { value: 'BRL', symbol: '$', label: 'Brazilian real' },
  { value: 'CAD', symbol: '$', label: 'Canadian dollar' },
  { value: 'CHF', symbol: 'CHF', label: 'Swiss franc' },
  { value: 'CNY', symbol: '¥', label: 'Chinese yuan' },
  { value: 'CZK', symbol: 'Kč', label: 'Czech koruna' },
  { value: 'DKK', symbol: 'kr', label: 'Danish krone' },
  { value: 'HKD', symbol: '$', label: 'Hong Kong dollar' },
  { value: 'HRK', symbol: 'Kn', label: 'Croatian kuna' },
  { value: 'HUF', symbol: 'Ft', label: 'Hungarian forint' },
  { value: 'IDR', symbol: 'Rp', label: 'Indonesian rupiah' },
  { value: 'ILS', symbol: '₪', label: 'Israeli new shekel' },
  { value: 'INR', symbol: '₹', label: 'Indian rupee' },
  { value: 'ISK', symbol: 'Kr', label: 'Icelandic króna' },
  { value: 'JPY', symbol: '¥', label: 'Japanese yen' },
  { value: 'KRW', symbol: '₩', label: 'South Korean won' },
  { value: 'MXN', symbol: '$', label: 'Mexican peso' },
  { value: 'MYR', symbol: 'RM', label: 'Malaysian ringgit' },
  { value: 'NOK', symbol: 'kr', label: 'Norwegian krone' },
  { value: 'NZD', symbol: '$', label: 'New Zealand dollar' },
  { value: 'PHP', symbol: '₱', label: 'Philippine peso' },
  { value: 'PLN', symbol: 'zł', label: 'Polish złoty' },
  { value: 'RON', symbol: 'L', label: 'Romanian leu' },
  { value: 'RUB', symbol: '₽', label: 'Russian ruble' },
  { value: 'SEK', symbol: 'kr', label: 'Swedish krona' },
  { value: 'THB', symbol: '฿', label: 'Thai baht' },
  { value: 'TRY', symbol: '₺', label: 'Turkish lira' },
  { value: 'ZAR', symbol: 'R', label: 'South African rand' },

  //{ value: 'AED', symbol: 'د.إ', label: 'United Arab Emirates dirham' },
  //{ value: 'AFN', symbol: 'Af', label: 'Afghan afghani' },
  //{ value: 'ALL', symbol: 'L', label: 'Albanian lek' },
  //{ value: 'AMD', symbol: 'Դ', label: 'Armenian dram' },
  //{ value: 'ANG', symbol: 'ƒ', label: 'Netherlands Antillean guilder' },
  //{ value: 'AOA', symbol: 'Kz', label: 'Angolan kwanza' },
  // { value: 'ARS', symbol: '$', label: 'Argentine peso' },
  //{ value: 'AWG', symbol: 'ƒ', label: 'Aruban florin' },
  //{ value: 'AZN', symbol: 'ман', label: 'Azerbaijani manat' },
  // {
  //   value: 'BAM',
  //   symbol: 'КМ',
  //   label: 'Bosnia and Herzegovina convertible mark',
  // },
  //{ value: 'BBD', symbol: '$', label: 'Barbadian dollar' },
  //{ value: 'BDT', symbol: '৳', label: 'Bangladeshi taka' },

  //  { value: 'BIF', symbol: '₣', label: 'Burundian franc' },
  //  { value: 'BMD', symbol: '$', label: 'Bermudian dollar' },
  //  { value: 'BND', symbol: '$', label: 'Brunei dollar' },
  //  { value: 'BOB', symbol: '$', label: 'Bolivian boliviano' },

  //   { value: 'BSD', symbol: '$', label: 'Bahamian dollar' },
  //   { value: 'BWP', symbol: '$', label: 'Botswana pula' },
  //  { value: 'BYN', symbol: '$', label: 'Belarusian ruble' },
  //   { value: 'BZD', symbol: '$', label: 'Belize dollar' },

  //  { value: 'CDF', symbol: '₣', label: 'Congolese franc' },

  //{ value: 'CLP', symbol: '$', label: 'Chilean peso' },

  //   { value: 'COP', symbol: '$', label: 'Colombian peso' },
  //  { value: 'CRC', symbol: '₡', label: 'Costa Rican colón' },
  //  { value: 'CVE', symbol: '$', label: 'Cape Verdean escudo' },

  //{ value: 'DJF', symbol: '₣', label: 'Djiboutian franc' },

  //{ value: 'DOP', symbol: '$', label: 'Dominican peso' },

  // { value: 'DZD', symbol: 'د.ج', label: 'Algerian dinar' },
  // { value: 'EGP', symbol: '£', label: 'Egyptian pound' },
  // { value: 'ETB', symbol: '$', label: 'Ethiopian birr' },
  // { value: 'FJD', symbol: '$', label: 'Fijian dollar' },
  // { value: 'FKP', symbol: '£', label: 'Falkland Islands pound' },

  // { value: 'GEL', symbol: '$', label: 'Georgian lari' },
  // { value: 'GIP', symbol: '£', label: 'Gibraltar pound' },
  // { value: 'GMD', symbol: 'D', label: 'Gambian dalasi' },
  // { value: 'GNF', symbol: '₣', label: 'Guinean franc' },
  // { value: 'GTQ', symbol: 'Q', label: 'Guatemalan quetzal' },
  // { value: 'GYD', symbol: '$', label: 'Guyanese dollar' },
  // { value: 'HNL', symbol: 'L', label: 'Honduran lempira' },

  //{ value: 'HTG', symbol: 'G', label: 'Haitian gourde' },

  //{ value: 'JMD', symbol: '$', label: 'Jamaican dollar' },

  //{ value: 'KES', symbol: 'Sh', label: 'Kenyan shilling' },
  // { value: 'KGS', symbol: 'KGS', label: 'Kyrgyzstani som' },
  // { value: 'KHR', symbol: '៛', label: 'Cambodian riel' },
  // { value: 'KMF', symbol: '₣', label: 'Comorian franc' },

  //{ value: 'KYD', symbol: '$', label: 'Cayman Islands dollar' },
  // { value: 'KZT', symbol: '〒', label: 'Kazakhstani tenge' },
  // { value: 'LAK', symbol: '₭', label: 'Lao kip' },
  // { value: 'LBP', symbol: 'ل.ل', label: 'Lebanese pound' },
  // { value: 'LKR', symbol: 'Rs', label: 'Sri Lankan rupee' },
  // { value: 'LRD', symbol: '$', label: 'Liberian dollar' },
  // { value: 'LSL', symbol: 'L', label: 'Lesotho loti' },
  // { value: 'MAD', symbol: 'د.م.', label: 'Moroccan dirham' },
  // { value: 'MDL', symbol: 'L', label: 'Moldovan leu' },
  // { value: 'MGA', symbol: 'MGA', label: 'Malagasy ariary' },
  // { value: 'MKD', symbol: 'ден', label: 'Macedonian denar' },
  // { value: 'MMK', symbol: 'K', label: 'Burmese kyat' },
  // { value: 'MNT', symbol: '₮', label: 'Mongolian tögrög' },
  // { value: 'MOP', symbol: 'P', label: 'Macanese pataca' },
  // { value: 'MRO', symbol: 'UM', label: 'Mauritanian ouguiya' },
  // { value: 'MUR', symbol: '₨', label: 'Mauritian rupee' },
  // { value: 'MVR', symbol: 'ރ.', label: 'Maldivian rufiyaa' },
  // { value: 'MWK', symbol: 'MK', label: 'Malawian kwacha' },

  // { value: 'MZN', symbol: 'MTn', label: 'Mozambican metical' },

  // { value: 'NAD', symbol: '$', label: 'Namibian dollar' },
  // { value: 'NGN', symbol: '₦', label: 'Nigerian naira' },
  // { value: 'NIO', symbol: '$', label: 'Nicaraguan córdoba' },

  // { value: 'NPR', symbol: '₨', label: 'Nepalese rupee' },

  // { value: 'PAB', symbol: 'PAB', label: 'Panamanian balboa' },
  //{ value: 'PEN', symbol: 'PEN', label: 'Peruvian sol' },
  //{ value: 'PGK', symbol: 'K', label: 'Papua New Guinean kina' },

  //{ value: 'PKR', symbol: '₨', label: 'Pakistani rupee' },

  //{ value: 'PYG', symbol: '₲', label: 'Paraguayan guaraní' },
  // { value: 'QAR', symbol: 'ر.ق', label: 'Qatari riyal' },

  //{ value: 'RSD', symbol: 'din', label: 'Serbian dinar' },

  //{ value: 'RWF', symbol: '₣', label: 'Rwandan franc' },
  //{ value: 'SAR', symbol: '﷼', label: 'Saudi riyal' },

  //{ value: 'SHP', symbol: '£', label: 'Saint Helena pound' },
  //{ value: 'SLL', symbol: 'Le', label: 'Sierra Leonean leone' },
  //{ value: 'SOS', symbol: 'Sh', label: 'Somali shilling' },
  //{ value: 'SRD', symbol: '$', label: 'Surinamese dollar' },
  //{ value: 'SZL', symbol: 'L', label: 'Swazi lilangeni' },

  //{ value: 'TJS', symbol: 'ЅМ', label: 'Tajikistani somoni' },
  //{ value: 'TOP', symbol: '$', label: 'Tongan paʻanga' },

  //{ value: 'TTD', symbol: '$', label: 'Trinidad and Tobago dollar' },
  //{ value: 'TWD', symbol: '$', label: 'New Taiwan dollar' },
  //{ value: 'TZS', symbol: 'Sh', label: 'Tanzanian shilling' },
  //{ value: 'UAH', symbol: '₴', label: 'Ukrainian hryvnia' },
  //{ value: 'UGX', symbol: 'Sh', label: 'Ugandan shilling' },
  //{ value: 'UYU', symbol: '$', label: 'Uruguayan peso' },
  //{ value: 'UZS', symbol: 'UZS', label: 'Uzbekistani soʻm' },
  //{ value: 'VND', symbol: '₫', label: 'Vietnamese đồng' },
  //{ value: 'VUV', symbol: 'Vt', label: 'Vanuatu vatu' },
  //{ value: 'XCD', symbol: '$', label: 'Eastern Caribbean dollar' },
  //{ value: 'XPF', symbol: '₣', label: 'CFP franc' },

  //{ value: 'ZMW', symbol: 'ZK', label: 'Zambian kwacha' },
];
