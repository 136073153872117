import React, { useState, useEffect } from 'react';
import {
  Typography,
  MenuItem,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from '@apollo/client';
import { ShoppingCartOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import {
  ADD_REMOVE_PRODUCT_TO_WISHLIST,
  DELETE_PRODUCT_FROM_WISHLIST,
} from '../../../graphQL/mutations/wishlist';
import { REMOVE_PRODUCT_FROM_CART } from '../../../graphQL/mutations/cart';
import { GET_PRODUCT_BY_SLUG } from '../../../graphQL/queries/product';
import CarouselImage from '../../shared/Carousel';
import { GET_DELIVERY_OPTIONS } from '../../../graphQL/queries/product';
import { ADD_TO_CART, UPDATE_CART } from '../../../graphQL/mutations/cart';
import { useFormik } from 'formik';
import ProductDescription from './productDescription';
import Similar from './Similar';
import { getFormattedVariant } from '../../../utils/variant';
import { useRouter } from 'next/router';
import EventBus from '../../../core/EventBus';
import * as storage from '../../../utils/storage';
import useMiddlewares from '../../../core/useMiddleware';
import { CURRENCY_LOCATIONS } from '../../../core/constants/constant';
import { addHttps } from '../../../utils/addHttps';

const useStyles = makeStyles(() => ({
  fontBold: {
    fontWeight: 800,
    color: '#000000',
  },
  labelPadding: {
    paddingLeft: '30px !important',
  },
  totalLabel: {
    textAlign: 'right',
    marginTop: '12px',
  },
  totalValue: {
    textAlign: 'left',
    marginTop: '12px',
  },
  titleGrid: {
    textAlign: 'center',
  },
  title: {
    marginBottom: '10px',
  },
  strikeThrough: {
    textDecoration: 'line-through',
    opacity: 0.33,
  },
  onSale: {
    color: '#F26B1D',
  },
}));

const ProductDetail = ({ productSlug, popUp, handleModalClose }) => {
  const classes = useStyles();
  const router = useRouter();
  const [addRemoveProductToWishlist] = useMutation(
    ADD_REMOVE_PRODUCT_TO_WISHLIST,
  );
  const [addProductToCart] = useMutation(ADD_TO_CART);
  const [updateCart] = useMutation(UPDATE_CART);
  const [isBookMarked, setBookmark] = useState(false);
  const [images, setImages] = useState([]);
  const [removeProductFromCart] = useMutation(REMOVE_PRODUCT_FROM_CART);
  const [hasCartProduct, setHasCartProduct] = useState(false);
  const [formattedVariants, setFormattedVariants] = useState([]);
  const [initialVariants, setInitialVariants] = useState({});
  const [deleteProductFromWishlist] = useMutation(DELETE_PRODUCT_FROM_WISHLIST);
  const [submitting, setSubmitting] = useState(false);
  const [initialShipping, setInitialShipping] = useState(1);
  const { isLoggedIn, isVendor } = useMiddlewares();
  const [productCurrency, setProductCurrency] = useState({
    value: 'AUD',
    symbol: '$',
  });

  const [cartProduct, setCartProduct] = useState({
    id: '',
    quantity: '',
    delivery_option_id: '',
    variant: '',
  });
  const [cartId, setCartId] = useState(0);
  const { data: product, loading: productLoading } = useQuery(
    GET_PRODUCT_BY_SLUG,
    {
      variables: {
        slug: productSlug,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { data: shippingType } = useQuery(GET_DELIVERY_OPTIONS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    let cartData = [];

    if (router && router?.query?.cart_id) {
      setHasCartProduct(true);
      setCartId(Number(router && router?.query?.cart_id));
      cartData = product?.getProductBySlug?.carts.filter(
        (data) => data.id == cartId,
      );
      if (cartData && cartData[0]) {
        setCartProduct(cartData[0]);
      }
    } else {
      if (product?.getProductBySlug?.deliveryOptions[0]?.id) {
        setInitialShipping(product?.getProductBySlug?.deliveryOptions[0]?.id);
      }
    }

    setBookmark(product && product.getProductBySlug.wishlisted);
    setImages(product && product.getProductBySlug.medias);
    if (hasCartProduct) {
      handleVariantsFromServer(
        product && product.getProductBySlug.variants,
        cartData && cartData[0]?.variant,
      );
    } else {
      handleVariantsFromServer(
        product && product.getProductBySlug.variants,
        '',
      );
    }
    if (product && product?.getProductBySlug?.currency) {
      const [currency] = CURRENCY_LOCATIONS.filter(
        (location) => location.value == product?.getProductBySlug?.currency,
      );
      setProductCurrency({
        value: currency.value,
        symbol: currency.symbol,
      });
    }
  }, [product, shippingType]);

  const getInitialVariants = (formattedVariants) => {
    const obj = {};
    formattedVariants &&
      formattedVariants.forEach((data) => {
        obj[data.key] = data.value[0].name;
      });
    return obj;
  };

  const quantity = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: hasCartProduct
        ? Number(cartProduct?.quantity)
        : quantity && quantity[0].value,
      shipping: hasCartProduct
        ? cartProduct?.delivery_option_id
        : initialShipping,
    },

    onSubmit: (formValue) => {
      setSubmitting(true);
      if (!isLoggedIn) {
        router.push('/login');
        return;
      }

      if (hasCartProduct) {
        updateCartProduct(formValue);
      } else {
        addToCart(formValue);
      }
    },
  });

  const addToCart = (values) => {
    const variants = Object.keys(initialVariants).map((value) => {
      return {
        key: value,
        value: initialVariants[value],
      };
    });

    const data = {
      product_id: product && product.getProductBySlug.id,
      delivery_option_id: values.shipping,
      quantity: values.quantity,
      variant: variants,
    };
    addProductToCart({
      variables: { data: data },
    })
      .then(() => {
        deleteProductFromWishlist({
          variables: { productId: product && product.getProductBySlug.id },
        }).then((res: any) => {
          if (!res) return;
          setSubmitting(false);
          EventBus.emit('notify', {
            message: 'Product successfully added to cart.',
            type: 'success',
          });
          EventBus.emit('cartIsEmpty', false);
          popUp && handleModalClose();
        });
      })
      .catch((err) => {
        setSubmitting(false);
        EventBus.emit('notify', {
          message: err.message,
          type: 'warning',
        });
        popUp && handleModalClose();
      });
  };

  const updateCartProduct = (values) => {
    const variants = Object.keys(initialVariants).map((value) => {
      return {
        key: value,
        value: initialVariants[value],
      };
    });

    const data = {
      product_id: product && product.getProductBySlug.id,
      delivery_option_id: values.shipping,
      quantity: values.quantity,
      variant: variants,
    };
    updateCart({
      variables: { data: data },
    })
      .then((res: any) => {
        setSubmitting(false);
        if (res && popUp) {
          handleModalClose();
        } else if (res) {
          setHasCartProduct(true);
          storage.set(
            'CART_UPDATED_MSG',
            JSON.stringify({
              message: 'Product successfully updated to cart.',
              type: 'success',
            }),
          );
          router.push('/cart');
        }
      })
      .catch((err) => {
        setSubmitting(false);
        if (err && popUp) {
          handleModalClose();
        } else if (err) {
          setHasCartProduct(true);
          storage.set(
            'CART_UPDATED_MSG',
            JSON.stringify({
              message: err.message,
              type: 'warning',
            }),
          );
          router.push('/cart');
        }
      });
  };

  const handleRemoveCartProduct = () => {
    removeProductFromCart({
      variables: { cartId: cartId },
    }).then((res: any) => {
      if (res) {
        storage.set(
          'CART_UPDATED_MSG',
          JSON.stringify({
            message: 'Product successfully removed from cart.',
            type: 'success',
          }),
        );
        router.push('/cart');
        setHasCartProduct(false);
      }
    });
  };

  const handleAddRemoveToWishlist = (productId) => {
    addRemoveProductToWishlist({ variables: { productId: productId } }).then(
      (res: any) => {
        if (res) {
          setBookmark(!isBookMarked);
          EventBus.emit('WishListRemoved', false);
        }
      },
    );
  };

  const handleVariantsFromServer = (variantdata, cartVariant) => {
    if (variantdata) {
      if (variantdata.length == 0) {
        return;
      }

      const formattedVariants = getFormattedVariant(variantdata);
      setFormattedVariants(formattedVariants);
      let initialVariantData;
      if (cartVariant) {
        const jsonVariant = JSON.parse(cartVariant);
        const cartVariants = getFormattedVariant(jsonVariant);
        initialVariantData = getInitialVariants(cartVariants);
      } else {
        initialVariantData = getInitialVariants(formattedVariants);
      }
      setInitialVariants(initialVariantData);
    }
  };

  const handleVariantChange = (e, key) => {
    setInitialVariants({ ...initialVariants, [key]: e.target.value });
  };

  const getShippingPrice = () => {
    const option = product?.getProductBySlug?.deliveryOptions.find(
      (o) => o.id === formik.values.shipping,
    );

    if (
      !option ||
      option.order === 1 ||
      option.order === 2 ||
      option.order === 5
    )
      return 0;

    if (option.order === 3) {
      return option.standard_shipping_price * formik.values.quantity;
    }

    if (option.order === 4) {
      return option.international_shipping_price * formik.values.quantity;
    }
  };

  if (productLoading) {
    return (
      <Grid style={{ textAlign: 'center', marginTop: '50%' }}>
        <CircularProgress size="3rem" />
      </Grid>
    );
  }

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {images && <CarouselImage images={images} />}
        </Grid>
        {!popUp && (
          <Grid item xs={12} className={classes.titleGrid}>
            <Typography component="h3" variant="h3" className={classes.title}>
              {product && product?.getProductBySlug.name}
            </Typography>
            <Typography variant="h6">
              <span
                className={
                  !!product?.getProductBySlug?.is_on_sale &&
                  product?.getProductBySlug?.sale_price &&
                  classes.strikeThrough
                }
              >
                {productCurrency?.symbol + product?.getProductBySlug?.price}{' '}
                {productCurrency?.value}{' '}
              </span>{' '}
              &nbsp;
              {!!product?.getProductBySlug?.is_on_sale &&
                product?.getProductBySlug?.sale_price && (
                  <>
                    <span>
                      {productCurrency?.symbol +
                        product?.getProductBySlug?.sale_price}{' '}
                      {productCurrency?.value}
                    </span>{' '}
                    &nbsp;
                    <span className={classes.onSale}>On sale</span>
                  </>
                )}
            </Typography>
          </Grid>
        )}

        {product?.getProductBySlug?.price > 0 && (
          <>
            <Grid
              item
              xs={4}
              className={classes.labelPadding}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography component="h6" variant="h6">
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                select
                className={classes.fontBold}
                onChange={formik.handleChange}
                id="quantity"
                name="quantity"
                variant="standard"
                value={formik.values.quantity}
                multiline={true}
                SelectProps={{
                  multiple: false,
                }}
              >
                {quantity.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}
      </Grid>

      {product?.getProductBySlug?.price > 0 &&
        formattedVariants.map((data, index) => {
          return (
            <Grid
              container
              spacing={2}
              key={index}
              style={{ padding: '8px 0px' }}
            >
              <Grid item xs={4} className={classes.labelPadding}>
                <Typography
                  component="h6"
                  variant="h6"
                  style={{ textTransform: 'capitalize' }}
                >
                  {data.key}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  select
                  className={classes.fontBold}
                  onChange={(e) => handleVariantChange(e, data.key)}
                  id="variants"
                  name={data.key}
                  variant="standard"
                  value={initialVariants[data.key]}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {data &&
                    data.value?.map((option, index) => (
                      <MenuItem key={index} value={option?.id}>
                        {option?.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          );
        })}

      <Grid container spacing={2}>
        {product?.getProductBySlug?.price > 0 && (
          <>
            <Grid
              item
              xs={4}
              className={classes.labelPadding}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography component="h6" variant="h6">
                Shipping
              </Typography>
            </Grid>

            {isVendor ? (
              <Grid item xs={8}>
                <TextField
                  select
                  className={classes.fontBold}
                  onChange={formik.handleChange}
                  id="shipping"
                  name="shipping"
                  variant="standard"
                  value={formik.values.shipping}
                  SelectProps={{
                    multiple: false,
                  }}
                >
                  {shippingType?.deliveryOptions.map((option, index) => (
                    <MenuItem key={index} value={option?.id}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <TextField
                  select
                  className={classes.fontBold}
                  onChange={formik.handleChange}
                  id="shipping"
                  name="shipping"
                  variant="standard"
                  value={formik.values.shipping}
                >
                  {product?.getProductBySlug?.deliveryOptions.map(
                    (option, index) => (
                      <MenuItem key={index} value={option?.id}>
                        {option?.name}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Grid>
            )}

            {getShippingPrice() !== 0 && (
              <>
                <Grid
                  item
                  xs={4}
                  className={classes.labelPadding}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography component="h6" variant="h6">
                    Shipping price
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    variant="standard"
                    value={
                      productCurrency?.symbol +
                      `${getShippingPrice()} ` +
                      productCurrency?.value
                    }
                    type={'text'}
                    disabled
                  />
                </Grid>
              </>
            )}

            {popUp && (
              <>
                {' '}
                <Grid
                  item
                  xs={4}
                  className={classes.labelPadding}
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Typography> Total</Typography>
                </Grid>
                <Grid item xs={8} className={classes.totalValue}>
                  <Typography variant="h6" className={classes.fontBold}>
                    {!!product?.getProductBySlug?.is_on_sale &&
                      product?.getProductBySlug.sale_price && (
                        <span>
                          {productCurrency?.symbol}
                          {product?.getProductBySlug?.sale_price &&
                            product?.getProductBySlug?.sale_price *
                              formik.values.quantity +
                              getShippingPrice()}{' '}
                          {productCurrency?.value}
                        </span>
                      )}{' '}
                    <span
                      className={
                        !!product?.getProductBySlug?.is_on_sale &&
                        product?.getProductBySlug.sale_price &&
                        classes.strikeThrough
                      }
                    >
                      {productCurrency?.symbol}
                      {product?.getProductBySlug?.price &&
                        product?.getProductBySlug?.price *
                          formik.values.quantity +
                          getShippingPrice()}{' '}
                      {productCurrency?.value}{' '}
                    </span>
                  </Typography>
                </Grid>{' '}
              </>
            )}
          </>
        )}

        {product?.getProductBySlug?.website_url && (
          <Grid
            item
            xs={12}
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
          >
            <a
              target="_blank"
              href={addHttps(product?.getProductBySlug?.website_url)}
              rel="noreferrer"
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="small"
                fullWidth={true}
              >
                View on Website
              </Button>
            </a>
          </Grid>
        )}

        {product?.getProductBySlug?.price > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '12px',
              width: '100%',
            }}
          >
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  {
                    handleAddRemoveToWishlist(
                      product && product?.getProductBySlug?.id,
                    );
                  }
                }}
              >
                {isBookMarked ? (
                  <Bookmark style={{ color: 'orange' }} />
                ) : (
                  <BookmarkBorder />
                )}
              </Button>
            </Grid>

            <Grid
              item
              style={{ marginLeft: '118px' }}
              xs={hasCartProduct ? 5 : 10}
            >
              {Number(product?.getProductBySlug?.quantity) == 0 ||
              product?.getProductBySlug?.out_of_stock ? (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={true}
                  color="secondary"
                >
                  <ShoppingCartOutlined /> &nbsp; Out of stock
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  color="primary"
                >
                  <ShoppingCartOutlined /> &nbsp;
                  {hasCartProduct ? 'Update' : 'Add to cart'}
                </Button>
              )}
            </Grid>
          </div>
        )}

        {hasCartProduct && (
          <Grid item xs={5} style={{ textAlign: 'left', marginTop: '15px' }}>
            <Button variant="contained" onClick={handleRemoveCartProduct}>
              Remove
            </Button>
          </Grid>
        )}

        {!popUp && (
          <>
            <ProductDescription product={product && product.getProductBySlug} />
            <Similar productId={product && product.getProductBySlug.id} />
          </>
        )}
      </Grid>
    </form>
  );
};

ProductDetail.propTypes = {
  productSlug: PropTypes.string,
  popUp: PropTypes.bool,
  handleModalClose: PropTypes.func,
};

export default ProductDetail;
